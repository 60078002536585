
html, body {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f9f9f9 !important;
  
  /* background-color: red !important; */
}
.MuiTextField-root {
  background-color: white;
}

.MuiDataGrid-main {
  background-color: white;
}

/* .container-closing-yellow.MuiDataGrid-row  {
  background-color: khaki;
}

.container-closing-yellow.MuiDataGrid-row :hover {
  background-color: darkkhaki !important;
}

.container-closing-red.MuiDataGrid-row {
  background-color: salmon;
  
} */